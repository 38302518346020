<template>
  <div class="card card-custom gutter-b">
    <div class="card-header flex-wrap py-3">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("curration.title") }}
          <span class="d-block text-muted pt-2 font-size-sm">{{
            $t("curration.subtitle")
          }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <v-flex class="mr-2">
          <collection_select />
        </v-flex>
        <v-flex>
          <curated_edit_dialog />
        </v-flex>
      </div>
    </div>
    <div class="card-body">
      <div
        id="kt_datatable_wrapper"
        class="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div class="row">
          <div class="col-sm-12">
            <v-data-table
              dense
              :headers="headers"
              :items="items"
              :options.sync="options"
              :loading="loading"
              :search="search"
              class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <template v-slot:item.queriesList="{ item }">
                {{ item.queriesList.join(", ") }}
              </template>
              <template v-slot:item.promotedList="{ item }">
                {{ item.promotedList.join(", ") }}
              </template>
              <template v-slot:item.hiddenList="{ item }">
                {{ item.hiddenList.join(", ") }}
              </template>
              <template v-slot:top>
                <div class="d-flex align-items-center position-relative my-1">
                  <span class="svg-icon svg-icon-1 position-absolute ms-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="17.0365"
                        y="15.1223"
                        width="8.15546"
                        height="2"
                        rx="1"
                        transform="rotate(45 17.0365 15.1223)"
                        fill="black"
                      ></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19
                              11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333
                              17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    v-model="search"
                    :placeholder="$t('curration.list.table.search_field')"
                    class="form-control form-control-solid w-250px ps-14"
                  />
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <a
                  @click="editItem(item)"
                  href="#"
                  class="btn btn-primary font-weight-bolder mr-2"
                >
                  {{ $t("common.edit") }}</a
                >
                <a
                  @click="deleteItem(item)"
                  href="#"
                  class="btn btn-danger font-weight-bolder"
                >
                  {{ $t("common.delete") }}</a
                >
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                  {{ $t("common.nodata") }}
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import {
  DELETE_CURATION,
  PULL_CURATIONS,
  REFRESH
} from "../../../core/services/store/curations.module";
import collection_select from "@/view/pages/collections/collection_select";
import Swal from "sweetalert2";
import curated_edit_dialog from "@/view/pages/curations/curated_edit_dialog.vue";

export default {
  components: { curated_edit_dialog, collection_select },
  data() {
    return {
      search: "",
      options: {},
      headers: [
        { text: this.$t("curration.list.table.headers.name"), value: "name" },
        {
          text: this.$t("curration.list.table.headers.queries"),
          value: "queriesList"
        },
        {
          text: this.$t("curration.list.table.headers.promoted"),
          value: "promotedList"
        },
        {
          text: this.$t("curration.list.table.headers.hidden"),
          value: "hiddenList"
        },
        {
          text: this.$t("curration.list.table.headers.actions"),
          value: "actions",
          sortable: false
        }
      ]
    };
  },
  methods: {
    reloadData() {
      if (this.selectedCollection && this.selectedCollection.id > 0) {
        this.$store.dispatch(PULL_CURATIONS, this.selectedCollection);
      }
    },
    refresh: function() {
      this.$store.dispatch(REFRESH);
    },
    editItem(item) {
      this.$router.push({ name: "curation-edit", query: { id: item.id } });
    },
    deleteItem: function(item) {
      Swal.fire({
        title: this.$t("common.dialog_delete.title"),
        text: this.$t("common.dialog_delete.content"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("common.dialog_delete.confirm")
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(DELETE_CURATION, item.id).then(() => {
            Swal.fire(this.$t("common.dialog_delete.success"), "", "success");
            this.reloadData();
          });
        }
      });
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    }
  },
  computed: {
    changeCollection() {
      return this.selectedCollection.id;
    },
    changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      errors: state => state.curations.errors,
      message: state => state.curations.message,
      hasMessage: state => state.curations.hasMessage,
      items: state => state.curations.curations,
      loading: state => state.curations.loading
    }),
    ...mapGetters(["selectedCollection", "currentProject"])
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("curration.title") }
    ]);
  }
};
</script>
